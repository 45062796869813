import Stack from "@mui/material/Stack"
import isEqual from "lodash-es/isEqual"
import React, { FC, memo } from "react"

import { Lottie } from "../lottie"

import styles from "./Loading.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Loading")

export type LoadingVariant = "primary" | "secondary"

export type LoadingSize = "xs" | "sm" | "md" | "lg" | "xl"

export type LoadingProps = {
  className?: string
  variant?: LoadingVariant
  size?: LoadingSize
}

export const Loading: FC<LoadingProps> = memo(
  ({ variant, size = "sm", ...props }) => (
    <Stack className={bem()} direction="row" spacing={2}>
      <Lottie
        animationName={isEqual(variant, "secondary") ? "LogoSecondary" : "Logo"}
        className={bem("animation", {
          [`size-${size}`]: true,
        })}
        {...props}
      />
    </Stack>
  ),
)

Loading.displayName = "Loading"
