export const ANIMATIONS = {
  Logo: "https://lottie.host/a5a35e24-d9d4-4faa-8639-7fec7ac64558/XmCCOjA0ml.lottie",
  LogoSecondary:
    "https://lottie.host/a5a35e24-d9d4-4faa-8639-7fec7ac64558/XmCCOjA0ml.lottie",
  NotFound:
    "https://lottie.host/e8fe35e8-e723-464f-9a33-aa57905af549/qzFAkPgSK0.lottie",
  NotFoundSecondary:
    "https://lottie.host/6bdef89c-d7c3-4a82-8052-a96a7d237655/wLKULhtgp4.lottie",
  Coins:
    "https://lottie.host/6b98c5bc-87ad-4929-8dac-784d42863d9d/AU2mU1d6N0.lottie",
  Email:
    "https://lottie.host/f3cdd902-cfa3-4b30-9dc1-d75a03396f71/25O1EJZKmx.lottie",
  EventPrivate:
    "https://lottie.host/a74850b3-9d80-41ad-997f-967b782a58c1/U7lDx5hRij.lottie",
  EventPublic:
    "https://lottie.host/5b674594-9d9d-4875-b3a6-d03ef313d6f7/rLWestbCJt.lottie",
  Event:
    "https://lottie.host/45394938-1895-416c-9e00-c895eff0df5e/NFR74F4Vq0.lottie",
  Feedback:
    "https://lottie.host/34c5a3b0-4f5a-4f54-acac-e6712e50e7ae/dJwdXnTllm.lottie",
  FeedbackSecondary:
    "https://lottie.host/8e742809-ce36-4233-9352-9a51cb7c1db2/8iRGhNYQvk.lottie",
  Filter:
    "https://lottie.host/475b6f55-7bdf-4a11-af62-d72858a3ee61/RlGLsunsg0.lottie",
  Map: "https://lottie.host/5ef67c12-c5ca-4f7f-b7f7-51baf51262ff/sw4jnwGEk2.lottie",
  Messages:
    "https://lottie.host/666f9175-3c6c-4ed1-a0c0-79ed4a12cbc0/39kUxx5RWw.lottie",
  PremiumSuccessful:
    "https://lottie.host/f96786d7-09ab-4964-b11f-c86f5a1fec0f/ZSVtJJuYh1.lottie",
  SearchProfiles:
    "https://lottie.host/05ee10bf-9aed-469c-8f8e-65cb76db29b7/HyItZhZcJh.lottie",
  Search:
    "https://lottie.host/37830091-e5ce-4cbf-bb96-e17700cbd648/4BII4i5JIU.lottie",
  ReadingPaper:
    "https://lottie.host/1a78b477-985d-47ba-ab0e-91c2cbc54bf7/BzpWu6YnCs.lottie",
  PhoneTouch:
    "https://lottie.host/8867f956-adc2-4326-a8bd-82400b92f3f4/gkK5DK1jTQ.lottie",
  Analyse:
    "https://lottie.host/d2a80a4b-177e-49cd-850a-f2b2294b06d0/11WQbzZsO9.lottie",
}
