"use client"
import MatButton from "@mui/material/Button"
import { useTheme } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import isEqual from "lodash-es/isEqual"
import isUndefined from "lodash-es/isUndefined"
import React, { FC, ReactNode, useRef } from "react"

import { Icon, getIconSize } from "../icon"
import { Link } from "../link"
import { Loading } from "../loading"

import { ButtonProps, ButtonDefaultProps } from "./Button.model"
import styles from "./Button.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Button")

export const Button: FC<ButtonProps> = ({
  fullWidth,
  color = "primary",
  iconProps = {},
  loading,
  variant = "contained",
  size = "large",
  className,
  contentClassName,
  disabled,
  redirect,
  ...props
}) => {
  const theme = useTheme()
  const labelRef = useRef<HTMLSpanElement | null>(null)
  const isOutlined = variant === "outlined"
  const isText = variant === "text"
  const iconName = iconProps?.name
  const isIconOnly = iconName && !(props as ButtonDefaultProps)?.title
  const contentModifier = {
    [theme.palette.mode]: true,
    "icon-only": !!isIconOnly,
  }

  const renderInnerContent = () => (
    <span ref={labelRef} className={bem("title")}>
      {iconName && (
        <Icon
          className={bem("icon", {
            "icon-only": !!isIconOnly,
          })}
          {...iconProps}
        />
      )}
      {(props as ButtonDefaultProps)?.title}
    </span>
  )

  const renderContent = () => {
    if (loading) {
      return (
        <Loading
          size="xs"
          variant={isEqual(color, "secondary") ? "secondary" : "primary"}
        />
      )
    }
    return renderInnerContent()
  }

  const renderLink = (children: ReactNode) =>
    !isUndefined(redirect) ? (
      <Link
        hasBackground
        disabled={disabled}
        variant={variant}
        className={bem("link", {
          [`${theme.palette.mode}--${color}`]: true,
          "is-not-outlined": variant !== "outlined",
          "has-icon-only": !!isIconOnly,
        })}
        {...redirect}
      >
        {children}
      </Link>
    ) : (
      children
    )

  const renderVariant = () => {
    if (isOutlined) {
      return (
        <div className={bem("content", contentModifier, contentClassName)}>
          {renderContent()}
        </div>
      )
    }
    return renderContent()
  }

  let fontSize = isOutlined ? 15 : 16
  switch (size) {
    case "small":
      fontSize = isOutlined ? 13 : 14
      break
    case "medium":
      fontSize = isOutlined ? 14 : 15
      break
  }
  const labelWidth = labelRef?.current?.offsetWidth
  const width = labelWidth ? labelWidth + 30 : "auto"
  const iconSize = isIconOnly && getIconSize(iconProps?.size)
  const sx = {
    minWidth: !isIconOnly && !redirect ? width : "auto",
    width: iconSize ? iconSize + 12 : "auto",
    height: iconSize ? iconSize + 12 : "auto",
    padding: redirect ? 0 : !isIconOnly ? "8px 16px" : "16px",
    borderRadius: !isIconOnly ? "15px" : "1000px",
    textTransform: "none",
    fontWeight: "600",
    fontSize,
    boxShadow: theme.shadows[1],
    background: theme.gradiant?.[color],
    ...(isOutlined && {
      minWidth: !isIconOnly ? width : (iconSize ?? "auto"),
      width: "auto",
      height: iconSize ? iconSize * 2 + 4 : "auto",
      fontWeight: "400",
      borderWidth: 0,
      padding: "2px",
      color: theme.palette.inherit.main,
    }),
    ...(isText && {
      background: theme.palette.backgroundWhite?.main,
    }),
    ...(isOutlined &&
      color === "warning" && {
        color: theme.palette.warning.main,
      }),
    ...(isOutlined &&
      color === "error" && {
        color: theme.palette.error.main,
      }),
    ":hover": {
      ...(isOutlined && {
        borderWidth: 0,
      }),
    },
  } as SxProps
  return (
    <MatButton
      {...props}
      aria-label={props?.["aria-label"] ?? (props as ButtonDefaultProps)?.title}
      color={color}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      sx={sx}
      tabIndex={!isUndefined(redirect) || disabled ? -1 : undefined}
      variant={variant}
      className={bem(
        undefined,
        {
          "has-fullWidth": !!fullWidth,
        },
        className,
      )}
    >
      {renderLink(renderVariant())}
    </MatButton>
  )
}
