"use client"
import { useTheme } from "@mui/material/styles"
import React, { FC, memo } from "react"

import { BaseLink } from "../base-link"

import { LinkProps } from "./Link.model"

import styles from "./Link.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Link")

export const Link: FC<LinkProps> = memo(
  ({
    variant = "inherit",
    href,
    children,
    className,
    locale,
    style,
    target,
    itemProp,
    hasBackground,
    onMouseEnter,
    onClick,
    ariaLabel,
  }) => {
    const theme = useTheme()
    const linkModifier = {
      [theme.palette.mode]: !hasBackground,
      [`${theme.palette.mode}--${variant}`]: !hasBackground,
    }
    const defaultProps = {
      className: bem(undefined, linkModifier, className),
      style,
      onMouseEnter,
    }
    if (onClick && !href) {
      return (
        <span tabIndex={0} onClick={onClick} {...defaultProps}>
          {children}
        </span>
      )
    }
    return (
      <BaseLink
        {...defaultProps}
        aria-label={ariaLabel}
        href={href}
        itemProp={itemProp}
        locale={locale}
        target={target ?? undefined}
        onClick={onClick}
      >
        {children}
      </BaseLink>
    )
  },
)

Link.displayName = "Link"
