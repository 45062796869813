"use client"
import dynamic from "next/dynamic"
import Image from "next/image"
import React, { FC, memo } from "react"

import { LottieProps } from "./Lottie.model"

import styles from "./Lottie.module.scss"
import { ANIMATIONS } from "./LottieAnimations"

import { create } from "@/helpers/bem"

import BackgroundPattern from "@/public/svg/mymatey-background-pattern.svg"

const DotLottieReact = dynamic(
  () =>
    import("@lottiefiles/dotlottie-react").then(
      (module) => module.DotLottieReact,
    ),
  { ssr: false },
)

const bem = create(styles, "Lottie")

export const Lottie: FC<LottieProps> = memo(
  ({ className, variant, animationName, containerClassName, ...props }) => {
    if (!ANIMATIONS?.[animationName]) return null
    return (
      <div className={bem("container", undefined, containerClassName)}>
        <DotLottieReact
          autoplay
          autoResizeCanvas
          loop
          className={bem(undefined, undefined, className)}
          src={ANIMATIONS?.[animationName]}
          useFrameInterpolation={false}
          renderConfig={{
            devicePixelRatio: 1,
          }}
          {...props}
        />
        <Image
          fill
          alt="Animation background pattern"
          src={BackgroundPattern}
          className={bem("background", {
            [`${variant}`]: !!variant,
          })}
        />
      </div>
    )
  },
)

Lottie.displayName = "Lottie"
