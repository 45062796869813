import dynamic from "next/dynamic"
import React, { FC, Suspense, memo } from "react"

import { IconSize, IconProps, IconName } from "./Icon.model"

export const getIconSize = (size?: IconSize) => {
  switch (size) {
    case "xs":
      return 15
    case "sm":
      return 20
    case "md":
      return 30
    case "lg":
      return 40
    case "xl":
      return 50
    default:
      return size ?? 16
  }
}

const convertIconName = (name: IconName): string =>
  name
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/(\d+)/g, "-$1")
    .toLowerCase()

const LazyIconComponent = (iconName: IconName) =>
  dynamic(() =>
    import(`react-feather/dist/icons/${convertIconName(iconName)}`).then(
      (module) => ({ default: module?.default }),
    ),
  )

export const Icon: FC<IconProps> = memo(({ name, size, ...props }) => {
  if (!name) {
    return null
  }
  const Component = LazyIconComponent(name) as FC<IconProps>
  if (!Component) {
    return null
  }
  return (
    <Suspense>
      <Component
        aria-hidden="true"
        role="presentation"
        size={getIconSize(size)}
        {...props}
      />
    </Suspense>
  )
})

Icon.displayName = "Icon"
